import React from "react"
import { Link } from "gatsby"
import "../styles/global.css"

interface Props {
  title: string
  twitter: string
  children?: any
}

const Layout = ({ title, twitter, children }: Props) => {
  return (
    <div className="p-10">
      <header className="mb-6">
        <h1 className="font-semibold text-2xl inline-block mr-4 ">
          <Link to={`/`}>{title}</Link>
        </h1>
        <nav className="inline-block">
          <ul>
            <li className="inline mx-1">
              <Link to="/">Posts</Link>
            </li>
            <li className="inline mx-1">
              <Link to="/about">About</Link>
            </li>
            <li className="inline mx-1">
              <a href={`https://twitter.com/${twitter}`} target="_blank">
                Follow me
              </a>
            </li>
          </ul>
        </nav>
      </header>
      <main className="mb-6">{children}</main>
    </div>
  )
}

export default Layout
